import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const SolpedsEdit = lazy(() => import('@/containers/Views/Solpeds/SolpedsForm/SolpedsEdit'));
const SolpedsNew = lazy(() => import('@/containers/Views/Solpeds/SolpedsForm/SolpedsNew'));
const SolpedsPage = lazy(() => import('@/containers/Views/Solpeds/SolpedsPage'));
const WorkSupplyOrders = lazy(() => import('@/containers/Views/SupplyOrders/WorkSupplyOrders'));

const SolpedsRouter = (
  <Route path="solpeds">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Find}
        >
          <SolpedsPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Edit}
        >
          <SolpedsEdit />
        </PrivateRoute>
      )}
    />
    <Route 
      path="create"
      element={(
        <PrivateRoute 
          pageName={EPage.Solpeds} 
          permissions={EBasicActions.Create}
        >
          <SolpedsNew />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":solpedId/supply_order"
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Create}
        >
          <WorkSupplyOrders />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default SolpedsRouter;
