import React, { useState } from 'react';
import { 
  Button, 
  Col, 
  Empty, 
  Modal, 
  Row, 
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DropboxOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import useAuth from '@/hooks/useAuth';
import usePaginationService, { PaginationResponse } from '@/hooks/usePaginationService';
import IInventoryProduct from '@/interfaces/IInventoryProduct';
import { getInventoryProductDetails, getInventoryProductsPaginated } from '@/services/InventoryProduct';
import InventoryProductsList from '@/shared/components/lists/InventoryProductsList';
import InventoryProductDetailsList from '@/shared/components/lists/InventoryProductDetailsList';
import IInventoryProductDetail from '@/interfaces/IInventoryProductDetail';
import FolioScrollList, { IOnGetFolios } from '@/containers/Views/Inventory/components/FolioScrollList';
import { getFoliosPaginated } from '@/services/Folio';
import ActionButton from '@/interfaces/ActionButton';
import { breakLPN } from '@/services/LPNsService';
import { notification } from '@/shared/helpers';
import { Product } from '@/interfaces/ProductRefactor';
import PickUpJourney from '@/shared/components/forms/pickJourney/PickUpJourney';
import LetDownJourney from '@/shared/components/forms/letDownJourney/LetDownJourney';
import { useJourney } from '@/hooks/useJourney';
import Folio from '@/interfaces/Folio';

interface MyPersonalRackPickingProps {}

const MyPersonalRackPicking = (props: MyPersonalRackPickingProps) => {
  const auth = useAuth();
  const rackId = auth?.rack?.id;
  if (!rackId) {
    throw new Error('required rack id');
  }

  const { t } = useCustomTranslation();
  const { confirm } = Modal;


  const pickUpJourney = useJourney();
  const letDownJourney = useJourney();
  const [foliosInventory, setFoliosInventory] = useState<{[key: string]: PaginationResponse<Folio>}>({});
  const inventoryDetailsDataState = usePaginationService<IInventoryProduct>(
    {
      pageSize: 5,
      currentPage: 1,
      params: {
        rackId: auth?.rack?.id,
        warehouseId: auth.warehouse,
      },
      fetchPage: getInventoryProductsPaginated,
    },
  );

  const reloadData = async () => {
    console.log('entered reloadData');
    await inventoryDetailsDataState.load();
  };

  const confirnBreakLpn = async (lpnId: string, userId: number) => {
    confirm({
      title: t('g.break_lpn'),
      icon: <ExclamationCircleOutlined />,
      content: `${t('g.do_you_want_to_break_this_lpn?')}`,
      okText: t('g.break_lpn'),
      cancelText: t('g.cancel'),
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            breakLPN(lpnId, userId)
              .then(() => {
                notification('success', t('g.done'), 'KEY_BREAK_LPN_SUCCESS');
                reloadData();
                resolve('Nice');
              })
              .catch((error) => {
                console.error(error);
                notification('error', t('g.error'), 'KEY_BREAK_LPN_ERROR');
                reject(Error('It broke'));
              });
          });
        } catch {
          return console.log('Oops errors!');
        }
      },
      onCancel() {},
    });
  };

  const productDetailActions = (inventoryProductDetail: IInventoryProductDetail, params: any) => {
    const arrayActionButtons: ActionButton[] = [];
    if (inventoryProductDetail.lpnId !== null && auth?.staff?.id) {
      const { lpnId } = inventoryProductDetail;
      const userId = auth.staff.id;
      arrayActionButtons.push({
        icon: <DropboxOutlined />,
        func: async () => confirnBreakLpn(lpnId, userId),
      });
    }

    return arrayActionButtons;
  };

  const inventoryDetail = (record: IInventoryProduct) => (
    <InventoryProductDetailsList
      // eslint-disable-next-line
      actionBtns={productDetailActions}
      reloadService={false}
      params={{
        params: {
          rackId,
          // eslint-disable-next-line
          productId: record.productId,
        },
      }}
      service={getInventoryProductDetails}
      expandable={{
        rowExpandable: (ipdRecord: IInventoryProductDetail) => ipdRecord.useFolio,
        expandedRowRender: inventoryProductDetail(record.productId),
      }}
    />
  );
  
  const inventoryProductDetail = (productId: Product['id']) => (ipdRecord: IInventoryProductDetail) => (
    <FolioScrollList
      reloadService={false}
      data={foliosInventory[`productId-${productId}-rackId-${rackId}`]}
      service={onGetFolios}
      params={{
        rackId,
        productId,
        // eslint-disable-next-line
        productInventoryId: ipdRecord.productInventoryId ?? undefined,
        // eslint-disable-next-line
        lpnInventoryId: ipdRecord.lpnInventoryId ?? undefined,
      }}
    />
  );

  const onGetFolios = async ({
    productId,
    rackId,
    skip = 0,
    limit = 10,
  }: IOnGetFolios) => {
    const data = await getFoliosPaginated(skip, limit, {
      productId,
      rackId,
    });

    setFoliosInventory(prev => ({
      ...prev,
      [`productId-${productId}-rackId-${rackId}`]: data,
    }));
  };

  return (
    <Row>
      <PickUpJourney 
        journey={pickUpJourney}
        reloadData={inventoryDetailsDataState.load} 
      />
      <LetDownJourney 
        journey={letDownJourney}
        reloadData={inventoryDetailsDataState.load} 
      />
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Button 
              type="primary" 
              icon={<ArrowUpOutlined className="align-icon" />} 
              size="large" 
              onClick={() => pickUpJourney.modal.show()}
            >
              {t('g.pick')}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<ArrowDownOutlined className="align-icon" />}
              size="large"
              onClick={() => letDownJourney.modal.show()}
            >
              {t('g.let_down')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {auth.rack?.id ? (
              <InventoryProductsList
                inventoryProducts={inventoryDetailsDataState.data}
                showPrice={false}
                showVisibility={false}
                showAvailableAndPending={false}
                loading={inventoryDetailsDataState.loading}
                expandable={{
                    expandedRowRender: inventoryDetail,
                }}
              />
            )
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('g.no_rack')} />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MyPersonalRackPicking;
