import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import {
  Button,
  Select,
  Drawer,
  Space,
} from 'antd';
import useAuth from '@/hooks/useAuth';
import useModal from '@/hooks/useModal';
import { getStaffRelatedWarehouses } from '@/services/Staff';
import useOperativePage from '@/hooks/useOperativePage';
import isScreenMobile from '@/shared/helpers/isScreenMobile';
import { MenuUnfoldOutlined, MenuFoldOutlined, SwapOutlined } from '@ant-design/icons';
import TopbarProfile from './TopbarProfile';
import MyPersonalRackPicking from './MyPersonalRackPicking';

const Topbar = ({ onClickCollapsed, collapsed }) => {
  const { t } = useCustomTranslation();
  const auth = useAuth();
  const myPersonalRackModal = useModal();

  const {
    isOperativePage,
    isSubOperativePage,
  } = useOperativePage();

  const [staffRelations, setStaffRelations] = useState([]);

  const { OptGroup, Option } = Select;

  const onChange = (warehouseId) => {
    auth.setWarehouseContext(warehouseId);
  };

  const setStaffWarehousesInSelect = async (staffId) => {
    const warehouses = await getStaffRelatedWarehouses({ staffId });

    const reducedList = groupWarehouses(warehouses);

    setStaffRelations(Object.values(reducedList));

    const contextExists = warehouses.some(wh => String(wh.id) === String(auth.warehouse));

    if (contextExists) {
      auth.setWarehouseContext(auth.warehouse);
      return;
    }

    if (!contextExists || !auth.warehouse) {
      auth.setWarehouseContext(warehouses[0]?.id || null);
    }
  };

  const groupWarehouses = (relatedWarehouses) => {
    const reducedList = relatedWarehouses.reduce((acc, warehouse) => {
      const { operativeId, operativeAlias } = warehouse;
      if (!acc[operativeId]) {
        acc[operativeId] = {
          id: operativeId,
          alias: operativeAlias,
          warehouses: [],
        };
      }

      acc[operativeId].warehouses.push({
        id: warehouse.id,
        alias: warehouse.alias,
      });
      return acc;
    }, {});

    return reducedList;
  };

  useEffect(() => {
    if (!auth.staff) {
      return;
    }

    setStaffWarehousesInSelect(auth.staff.id);
  }, [auth.staff, auth.warehouse]);

  const warehousesOptions = staffRelations.map(operative => (
    <OptGroup key={`op_${operative.id}`} label={operative.alias}>
      {
        operative.warehouses.map(warehouse => (
          <Option
            key={warehouse.id}
            value={warehouse.id}
            label={`${operative.alias} ${warehouse.alias}`}
          >
            {warehouse.alias}
          </Option>
        ))
      }
    </OptGroup>
  ));

  return (
    <>
      <Drawer
        open={myPersonalRackModal.visible}
        width="70%"
        title={t('g.move_products_in_inventory')}
        placement={isScreenMobile() ? 'bottom' : 'right'}
        height="90%"
        onClose={() => { myPersonalRackModal.hide(); }}
        bodyStyle={{ padding: isScreenMobile() ? 5 : undefined }}
        destroyOnClose
      >
        <MyPersonalRackPicking />
      </Drawer>
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Space align="center" style={{ marginLeft: 10 }}>
              <Button 
                type="text"
                size="large"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} 
                onClick={onClickCollapsed}
                style={{
                  alignContent: 'center',
                  display: 'grid',
                }}
              />
              <Link className="topbar__logo" to="/" />
            </Space>
          </div>
          <div className="topbar__right">
            <div className="topbar__sc">
              <div>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SwapOutlined className="align-icon" />}
                  onClick={() => (myPersonalRackModal.show())}
                />
              </div>
            </div>
            <div className="topbar__warehouse">
              <Select
                // defaultActiveFirstOption
                size="middle"
                showSearch
                optionFilterProp="label"
                dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                onChange={onChange}
                value={isOperativePage ? +(auth.warehouse) : 0}
                disabled={!isOperativePage || isSubOperativePage}
              >
                {isOperativePage ? warehousesOptions : (
                  <Option
                    key="global-warehouse"
                    value={0}
                    label={t('g.global')}
                  >
                    {t('g.global')}
                  </Option>
                )}
              </Select>
            </div>
            <div>
              <TopbarProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Topbar.propTypes = {
  onClickCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

export default Topbar;
