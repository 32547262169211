import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const AccountPage = lazy(() => import('@/containers/Views/Accounts/AccountsPage'));

const AccountsRouter = (
  <Route path="accounts">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Accounts} 
          permissions={EBasicActions.Find}
        >
          <AccountPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default AccountsRouter;
