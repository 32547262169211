import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const SupplyOrdersManagerPage = lazy(() => import('@/containers/Views/SupplyOrders/SupplyOrdersManagerPage'));
const NewSupplyOrders = lazy(() => import('@/containers/Views/SupplyOrders/NewSupplyOrders'));

const SupplyOrdersRouter = (
  <Route path="supply_orders">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Find}
        >
          <SupplyOrdersManagerPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="new"
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Create}
        >
          <NewSupplyOrders />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default SupplyOrdersRouter;
